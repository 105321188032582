import { PiecesAuterationDataProps, PiecesData } from "types/pieces";

export const register = async (data: PiecesData) => {
  const response = await fetch(
    "https://smartdc.linceonline.com.br/back/api/pecas/index.php",
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
  return response.json();
};

export const setUpdateValues = async (data: PiecesAuterationDataProps) => {
  const response = await fetch(
    "https://smartdc.linceonline.com.br/back/api/pecas/ajustes/ajuste.php",
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
  if (response.status === 200) return true;

  return false;
};
