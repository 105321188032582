import React from "react";
import i18n from "utils/i18n";

export const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-blue-950 text-white text-center p-4 mt-auto  w-full flex flex-col flex-center items-center justify-center gap-2">
      <p>&copy; {currentYear} - Smart DC</p>
      <div className="flex flex-row items-center justify-center space-x-4">
        <a className="hover:underline transition-all duration-500" href="/privacy-policy" target="_blank">{i18n.t('privacyPolicy')}</a>
        <a className="hover:underline transition-all duration-500" href="/use-terms" target="_blank">{i18n.t('useTerms')}</a>
      </div>
    </footer>
  );
};
